import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard  {

  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.token) {
      // if (window.location.hostname === environment.frontend.base) {
      //   window.location.href = 'https://insurgo.de';
      // } else {
      //   localStorage.setItem('redirectTo', JSON.stringify({ url: state.url, query: { } }));
      //   this.router.navigate(['/auth/anmelden']);
      // }
      localStorage.setItem('redirectTo', JSON.stringify({ url: state.url.split('?')[0], query: route.queryParams }));
      this.router.navigate(['/auth/anmelden']);
      return false;
    }
    return true;
  }
}
